import React from 'react'
import PropTypes from 'prop-types'
import { Main, Code, Name } from './styles'
import Logo from '../../components/logo'

const ErrorLayout = ({ code, name, children }) => (
  <Main>
    <Logo />
    <Code>{code}</Code>
    <Name>{name}</Name>
    {children}
  </Main>
)

Main.propTypes = {
  code: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}

export default ErrorLayout
