import React from 'react'
import ErrorLayout from '../layouts/error-layout'
import { P } from '../components/typography'

const NotFoundPage = () => (
  <ErrorLayout code="404" name="Not Found">
    <P white centered>We couldn't find this page.</P>
  </ErrorLayout>
)

export default NotFoundPage
