import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import { shade } from 'polished'

export const Main = styled.main`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${shade(0.5, '#BF2D38')};
`

export const Code = styled.span`
  font-size: 5rem;
  margin-top: 3rem;
  font-family: "Merit";
  text-transform: uppercase;
  color: white;
  text-align: center;

  ${breakpoint('tablet')`
    letter-spacing: 1px;
  `}

  ${breakpoint('desktop')`
    letter-spacing: 2px;
  `}
`

export const Name = styled.span`
  font-size: 3rem;
  margin-bottom: 2rem;
  font-family: "Merit";
  text-transform: uppercase;
  color: white;
  text-align: center;

  ${breakpoint('tablet')`
    letter-spacing: 1px;
  `}

  ${breakpoint('desktop')`
    letter-spacing: 2px;
  `}
`
